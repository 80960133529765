import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SimulatedDateService} from '../../services/simulated-date/simulated-date.service';
import {CdDatePipe} from '../custom-pipes/cd-date.pipe';

@Component({
  selector: 'app-simulated-date-info',
  templateUrl: './simulated-date-info.component.html',
  styleUrls: ['./simulated-date-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, CdDatePipe],
  standalone: true
})
export class SimulatedDateInfoComponent {
  constructor(private simulatedDateService: SimulatedDateService) {}

  isDateSimulated(): boolean {
    return this.simulatedDateService.isDateSimulated();
  }

  getDateSimulated(): Date {
    return this.simulatedDateService.getSimulatedDate();
  }

  resetSimulatedDate(): void {
    this.simulatedDateService.resetSimulatedDate();
  }
}
